import React from 'react'
import { TextInput, Checkbox, Button, Group, Box, Radio, Select, LoadingOverlay, NumberInput, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FaPhoneAlt } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { MdEmail } from "react-icons/md";
import { RiGraduationCapFill } from "react-icons/ri";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { MdWork } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { useGetTradeDetailsQuery } from '../redux/api/apiSlice';
import { useGetEducationDetailsQuery } from '../redux/api/apiSlice';
import { useGetExperienceDetailsQuery } from '../redux/api/apiSlice';
import { useEditProfileDetailsMutation } from '../redux/api/apiSlice';
 
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import store from "../redux/store"
import { useDisclosure } from '@mantine/hooks';
import { Trans, t } from '@lingui/macro';
import { useLingui } from "@lingui/react";
import OtpInput from 'react-otp-input';
import '@mantine/notifications/styles.css';
import { notifications } from '@mantine/notifications';
import { unwrapResult } from '@reduxjs/toolkit';

import { Notification } from '@mantine/core';

const EditProfile = () => {

    const { i18n } = useLingui();
   
    const token = useSelector((state) => state?.user?.token)
    const userDetails = useSelector((state) => state?.user.candidateData)
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedValue2, setSelectedValue2] = useState(null);
    const [selectedValue3, setSelectedValue3] = useState(null);
     
    


    const navigate = useNavigate()


    const handleSelectChange = (event) => {
        const filteredData = tradeData.filter(item => item.masterName === event);
        setSelectedValue(event)

    };
    const handleSelectChange2 = (event) => {
        const filteredData = educationData.filter(item => item.masterName === event);
        setSelectedValue2(event)

    };
    const handleSelectChange3 = (event) => {
        const filteredData = experienceData.filter(item => item.masterName === event);
        setSelectedValue3(event)

    };

    const [loading, { open: openLoading, close: closeLoading }] = useDisclosure(false);
 
    

    const { data: tradeData, isLoading, isError, isFetching, refetch } = useGetTradeDetailsQuery({
        skip: !token
    })
    const { data: educationData, isLoading: eduLoading, isError: eduError, isFetching: eduFetching, refetch: eduRefetch } = useGetEducationDetailsQuery({
        skip: !token
    })

    const { data: experienceData, isLoading: expLoading, isError: expError, isFetching: expFetching, refetch: expRefetch } = useGetExperienceDetailsQuery({
        skip: !token
    })


    const [editProfile] = useEditProfileDetailsMutation()
    



     



    useEffect(() => {
        if (token) {
            refetch();
            eduRefetch()
            expRefetch()
        }
    }, [token, refetch, eduRefetch, expRefetch]);


    const form = useForm({
        initialValues: {
            name: userDetails?.name ? userDetails?.name : "",
            age: userDetails?.age || '',
            gender: userDetails?.gender || '',
            mobile: userDetails?.mobileNo || '',
            email: userDetails?.email || '',
            trade: userDetails?.trade?.id?.toString() || '',
            education: userDetails?.education?.id?.toString() || '',
            experience: userDetails?.experience?.id?.toString() || '',
            bike: userDetails?.bikeAndDl?.toLowerCase() || '',
            passport: userDetails?.passport == null ? "" : userDetails?.passport == true ? "true" : "false"
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : <Trans>Invalid email</Trans>),
            // name: (value) => (value?.length < 2 ? <Trans>Name must have at least 2 letters</Trans> : null),
            name: (value) => {
                if (!value || value.trim().length < 2) {
                    return <Trans>Name must have at least 2 letters</Trans>;
                }
                if (/^\d+$/.test(value)) {
                    return <Trans>Enter Your Name</Trans>
                }
                return null;
            },
            mobile: (value) => (value?.length < 10 ? <Trans>Please enter a 10 digits mobile number 🤥</Trans> : value?.length > 13 ? <Trans>Please enter a 10 digits mobile number 🤥</Trans> : null),
            //mobile: (value) => (value?.length < 10 ? <Trans>Please enter a 10 digits mobile number 🤥</Trans> : null),
           // mobile: (value) => (!value ? <Trans>Number is Required</Trans> : null),
            trade: (value) => (value == '' ? <Trans>Trade is Required</Trans> : null),
            education: (value) => (value == '' ? <Trans>Education is Required</Trans> : null),
            experience: (value) => (value == '' ? <Trans>Experience is Required</Trans> : null),
            gender: (value) => (value == "" ? <Trans>Gender is Required</Trans> : null),
            bike: (value) => (value == '' ? <Trans>Driving License is required</Trans> : null),
            age: (value) => (value?.length == 0 ? <Trans>Age is Required</Trans> : value < 16 ? <Trans>Age must be 16 years or above</Trans> : null),
            passport: (value) => (value == '' ? <Trans>Passport is required</Trans> : null)
        },

    });


    const handleSubmit = (values) => {
        openLoading()
        const convertedObject = {
            name: values.name,
            age: parseInt(values.age, 10),
            gender: values.gender,
            mobileNo: parseInt(values.mobile, 10),
            email: values.email,
            tradeId: parseInt(values.trade, 10),
            educationId: parseInt(values.education, 10),
            experienceId: parseInt(values.experience, 10),
            bikeAndDl: values.bike,
            passport: values.passport
        };
        
        editProfile(convertedObject).then(() => {
            closeLoading()
            navigate('/');
        }).catch(() => {
            closeLoading()
        });

    }



     
 

    return (
        <>
        

            <Box pos="relative">
                <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                <Box >
                    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}
                        style={{ display: "flex", flexDirection: "column", gap: ".5rem", height: "auto", padding: "10px" }}>
                        <TextInput
                            withAsterisk
                            label={<Trans>What is Your Name ?</Trans>}
                            leftSection={<IoPerson />}
                            placeholder={t`Enter Your Name`}
                            {...form.getInputProps('name')}
                        />


                        <NumberInput
                            withAsterisk
                            label={<Trans>Age</Trans>}
                            placeholder={t`Enter Your Age`}
                            leftSection={<SlCalender />}
                            {...form.getInputProps('age')}
                            max={99}
                            maxLength={2}
                        />

                        <div>
                            <label style={{fontSize:"14px"}}><Trans>What is Your Gender?</Trans></label>
                            <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                                <Radio
                                    label={<Trans>Male</Trans>}
                                    value="male"
                                    checked={form.values.gender === 'male' ? true : false}
                                    onChange={(event) => form.setFieldValue('gender', event.currentTarget.value)}
                                />
                                <Radio
                                    label={<Trans>Female</Trans>}
                                    value="female"
                                    checked={form.values.gender === 'female' ? true : false}
                                    onChange={(event) => form.setFieldValue('gender', event.currentTarget.value)}
                                />
                            </div>
                            {form?.errors?.gender &&
                                <p style={{ display: "block", fontSize: "12px", color: "#FF5252", fontWeight: "500" }}>{<Trans>Gender is Required</Trans>}</p>
                            }
                        </div>

                        <div style={{margin:"0px 0"}}>
                            <TextInput
                                key={i18n.locale}
                                withAsterisk
                                label={<Trans>Your Mobile Number</Trans>}
                                leftSection={<FaPhoneAlt />}
                                placeholder={t`Enter Your Number`}
                                {...form.getInputProps('mobile')}
                            styles={{
                                input:{height:"45px"}
                            }}
                            />

                            <div style={{marginTop:"10px"}}>
                                    <span style={{ fontSize:"14px", marginRight:"20px"}}> <Trans>Verification Status</Trans></span>
                                    {!userDetails?.mobileNoVerified ? (
                                         <span style={{fontSize:"14px", padding:"10px", color:"red", }}>Not Verified</span> 
                                    ):
                                    (
                                        <span style={{fontSize:"14px", padding:"10px", color:"green", }}>Verified</span> 
                                    )
                                    }
                            </div>
                        </div>



                       <div >
                        <TextInput
                            withAsterisk
                            label={<Trans>Email</Trans>}
                            leftSection={<MdEmail />}
                            placeholder={t`Enter Your Email`}
                            {...form.getInputProps('email')}
                            styles={{
                                input:{height:"45px"}
                               }}
                        />

                        <div  style={{margin:"10px 0"}}>
                           <span style={{ fontSize:"14px", marginRight:"10px"}}> <Trans>Verification Status</Trans></span>
                                {!userDetails?.emailVerified ? (
                                    <span style={{fontSize:"14px", padding:"10px", color:"red", }}>Not Verified</span> 
                                ):
                                (
                                    <span style={{fontSize:"14px", padding:"10px", color:"green", }}>Verified</span> 
                                )
                                }
                        </div>
                       </div>




                        <Select
                            label={<Trans>Trade/Job Category</Trans>}
                            placeholder={t`Select Trade`}
                            data={tradeData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange}
                            value={selectedValue}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('trade',)}
                        />


                        <Select
                            label={<Trans>Your Education Qualification</Trans>}
                            placeholder={t`Select Qualification`}
                            data={educationData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange2}
                            value={selectedValue2}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('education',)}
                        />

                        <Select
                            label={<Trans>Your Work Experience</Trans>}
                            placeholder={t`Select Work Experience`}
                            data={experienceData?.map((item) => ({
                                value: item?.id.toString(),   // Use the id as the value
                                label: item?.masterName // Display the masterName
                            }))}
                            searchable
                            onChange={handleSelectChange3}
                            value={selectedValue3}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('experience',)}
                        />


                        {/* <TextInput
                        withAsterisk
                        label="Your Education Qualification"
                        placeholder=" Enter Your Education"
                        leftSection={<RiGraduationCapFill />}
                        {...form.getInputProps('education')}
                    /> */}
                        {/* <TextInput
                        withAsterisk
                        label="Your Work Experience"
                        leftSection={<MdWork />}
                        placeholder=" Enter Your Experience"
                        {...form.getInputProps('experience')}
                    /> */}
                        {/* <TextInput
                        withAsterisk
                        label="License"
                        // placeholder="your@email.com"
                        {...form.getInputProps('bike')}
                    /> */}
                        <div>
                            <label style={{fontSize:"14px"}}><Trans>Do you have a bike or Driving License?</Trans></label>
                            <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                                <Radio
                                    label={<Trans>Yes</Trans>}
                                    value="yes"
                                    checked={form.values.bike === 'yes'}
                                    onChange={(event) => form.setFieldValue('bike', event.currentTarget.value)}
                                />
                                <Radio
                                    label={<Trans>No</Trans>}
                                    value="no"
                                    checked={form.values.bike === 'no'}
                                    onChange={(event) => form.setFieldValue('bike', event.currentTarget.value)}
                                />
                            </div>
                            {form?.errors?.bike &&
                                <p style={{ display: "block", fontSize: "12px", color: "#FF5252", fontWeight: "500" }}>{<Trans>Driving License is required</Trans>}</p>
                            }
                        </div>

                        {/* <div>
                            <label><Trans>Do you have a passport?</Trans></label>
                            <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                                <Radio
                                    label={<Trans>Yes</Trans>}
                                    value="yes"
                                    checked={form.values.passport === 'yes'}
                                    onChange={(event) => form.setFieldValue('passport', event.currentTarget.value)}
                                />
                                <Radio
                                    label={<Trans>No</Trans>}
                                    value="no"
                                    checked={form.values.passport === 'no'}
                                    onChange={(event) => form.setFieldValue('passport', event.currentTarget.value)}
                                />
                            </div>
                        </div> */}

                        <div>
                            <label style={{fontSize:"14px"}}><Trans>Do you have Passport ?</Trans></label>
                            <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                                <Radio
                                    label={<Trans>Yes</Trans>}
                                    value="true"
                                    checked={form.values.passport === "true" ? true : false}
                                    onChange={(event) => form.setFieldValue('passport', event.currentTarget.value)}
                                />
                                <Radio
                                    label={<Trans>No</Trans>}
                                    value="false"
                                    checked={form.values.passport === "false" ? true : false}
                                    onChange={(event) => form.setFieldValue('passport', event.currentTarget.value)}
                                />
                            </div>
                            {form?.errors?.passport &&
                                <p style={{ display: "block", fontSize: "12px", color: "#FF5252", fontWeight: "500" }}>{<Trans>Passport is required</Trans>}</p>
                            }
                        </div>

                        <Group justify="center" mt="md" >
                            <Button

                                type="submit" style={{
                                    width: '100%',
                                    marginBottom: '5rem',
                                }}><Trans>Save</Trans></Button>
                        </Group>
                    </form>
                </Box >
            </Box>

         
        </>
    )
}

export default EditProfile
