import { useEffect, useState } from "react";
import "./App.css";
import BottomNavigation from "./components/BottomNavigation";
import MainHeader from "./components/MainHeader";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

import { Routes, Route, Navigate } from "react-router-dom";
import { Text, Skeleton } from '@mantine/core';
import Home from "./screens/Home";
import Jobs from "./screens/Jobs";
import Login from "./screens/Login";
import Settings from "./screens/Settings";
import JobDescription from "./screens/JobDescription";
import { AuthContextProvider } from "./context/AuthContext";
import EditProfile from "./components/EditProfile";
import MyProfile from "./components/MyProfile";
import { UserAuth } from "./context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { appToken, candidateData, userData } from "./redux/userSlice";
import Eductaion from "./components/Eductaion";
import EditEducation from "./components/EditEducation";
import OtherDetails from "./components/OtherDetails";
import Experience from "./components/Experience";
import JobCategory from "./screens/JobCategory";
import JobLocation from "./screens/JobLocation";
import { defaultLocale, loadCatalog } from "../i18n";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  useEditProfileDetailsMutation,
  useGetProfileDetailsQuery,
} from "./redux/api/apiSlice";
import { fcmToken } from "./redux/userSlice";
import ScrollToTop from "./components/Scrolltotop";
import EditExperience from "./components/EditExperience";
import Course from "./screens/Course";
import Play from "./screens/Play";
import Shraminshala from "./screens/Shraminshala";
import OfflineSection from "./components/OfflineSection.jsx";
import { useLocation } from "react-router-dom";
import CreateProfileOnboarding from "./components/CreateProfileOnboarding.jsx";
import { messaging } from "./configFirebase.jsx";
import Coursedescription from "./screens/Coursedescription";
import Landinggreaternoida from "./screens/Landinggreaternoida";
import JobSearchResult from "./components/JobSearchResult.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";

import OnboardingQuestions from "./screens/OnboardingQuestions.jsx";

import AppliedJob from "./screens/AppliedJob.jsx";
import PublicJobSearchResult from "./components/PublicJobsearchresult.jsx";
import Policy from "./components/Policy.jsx";
import Chatbot from "./screens/Chatbot.jsx";
import ChatbotSingleQuestionAnswer from "./components/ChatboartSingleQuestionAnswer.jsx";
import WhiteCollar from "./screens/WhiteCollar.jsx";
import StateJobSection from "./components/StateJobSectionInHome.jsx";
import ExperienceJobSection from "./components/ExperienceJobSectionInHome.jsx";
import SalaryJobSection from "./components/SalaryJobSectionInHome.jsx";
import Predictedsalary from "./components/Salarypredictor/Predictedsalary.jsx";
import SalaryPredictor from "./components/Salarypredictor/Salarypredictor.jsx";
import HomeTrandingMidBannerDescription from "./components/HomeTrandingMidBannerDescription.jsx";
import Notification from "./screens/Notification.tsx";

import VerifyEmailComponent from './components/VerifyEmailComponent'
import TutorOnboard from "./components/EnglishTutor/TutorOnboard.jsx";
import TutorWheel from "./components/EnglishTutor/Tutorwheel.jsx";
import ComingSoon from "./components/EnglishTutor/ComingSoon.jsx";
import LeanEnglish from "./components/EnglishTutor/LearnEnglish.jsx";
import AITutor from "./components/EnglishTutor/AITutor.jsx";
import SportAI from "./components/EnglishTutor/SportAI.jsx";
import ShoppingAI from "./components/EnglishTutor/ShoppingAI.jsx";
import InterviewAI from "./components/EnglishTutor/InterviewAI.jsx";
import SavePrevUrl from "./components/SavePrevUrl.jsx";
import CreateProfileSingleOnboarding from "./components/CreateProfileSingleOnboarding.jsx";





function App() {
  var iOS =
    /iPad|iPhone|iPod|Mac/.test(navigator.userAgent) && !window.MSStream;
  const { user } = UserAuth();
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const location = useLocation();
  const { pathname } = location;
  const token = useSelector((state) => state?.user.token);
  const [loading, setLoading] = useState(true)


  const [editProfile] = useEditProfileDetailsMutation()
  //console.log("skcmskcm",user)

  const jsonObject = {
    attribution_details: localStorage.getItem('utmParams')
  }
  useEffect(() => {

    //console.log("skcmskcm")
    const timer = setTimeout(() => {
      setLoading(false);
      editProfile(jsonObject)
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer); // Cleanup the timer


  }, [user]);

  const [beforeInstallPrompt, setBeforeInstallPrompt] = useState(null);

  const queryClient = new QueryClient()

  const {
    data: candidateDetails,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetProfileDetailsQuery(userId, {
    skip: !token,
  });

  useEffect(() => {
    if (candidateDetails) {
      dispatch(candidateData(candidateDetails));
    }
  }, [candidateDetails]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadCatalog(defaultLocale);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the async function
    fetchData();

    const eventHandler = (event) => {
      setBeforeInstallPrompt(event);
    };

    const errorHandler = (event) => {
      console.log("error: " + event);
    };

    window.addEventListener("beforeinstallprompt", eventHandler);
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("beforeinstallprompt", eventHandler);
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register(
        import.meta.env.MODE === "production" ? "/sw.js" : "/dev-sw.js?dev-sw",
        { type: import.meta.env.MODE === "production" ? "classic" : "module" }
      )
      .then((registration) => {
        // navigator.serviceWorker.addEventListener('message', event => {
        //   if (event.data && event.data.offline) {
        //     setIsOffline(true);
        //   }
        // });
        // getToken(messaging, {
        //   vapidKey: import.meta.env.VITE_VAP_ID,
        //   serviceWorkerRegistration: registration
        // })
        //   .then((currentToken) => {
        //     console.log("Show Token ", currentToken);
        //     if (currentToken) {
        //       dispatch(fcmToken(currentToken))
        //     }
        //   });
      });
  }

  //useEffect(() => {
  const params = new URLSearchParams(window.location.search);
  console.log("Parameters " + params);
  const utmSource = params.get("utm_source")
    ? `utm_source=${params.get("utm_source")}`
    : null;
  const utmMedium = params.get("utm_medium")
    ? `utm_medium=${params.get("utm_medium")}`
    : null;
  const utmCampaign = params.get("utm_campaign")
    ? `utm_campaign=${params.get("utm_campaign")}`
    : null;

  // Create an array with all UTM parameters
  const utmParams = [utmSource, utmMedium, utmCampaign];
  console.log("UTM Parameters " + utmParams);

  // Filter out null or undefined values and join them into a single string
  const utmString = utmParams.filter((param) => param != null).join("&");
  if (utmString.length > 1) {
    localStorage.setItem("utmParams", utmString);
  }

  const handleOnline = () => setIsOffline(false);
  const handleOffline = () => setIsOffline(true);

  window.addEventListener("online", handleOnline);
  window.addEventListener("offline", handleOffline);

  //    return () => {
  //     window.removeEventListener('online', handleOnline);
  //     window.removeEventListener('offline', handleOffline);
  //  };
  //}, []);

  return (
    <I18nProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        {!iOS && isOffline ? (
          <OfflineSection />
        ) : (
          <div>
            <div className="container">

              <div className="app">
                {user && pathname !== "/landing-greater-noida" && (
                  <header className="app-header">
                    <MainHeader beforeInstallPrompt={beforeInstallPrompt} />
                  </header>
                )}
                <main
                  className="app-main"
                  style={{
                    marginTop:
                      user && pathname !== "/landing-greater-noida" && "58px",
                  }}
                >

                  <ScrollToTop />
                  <Routes>
                    <Route
                      index
                      element={

                        loading ? (
                          <div style={{ padding: "10px", display: "grid", gridTemplateRow: "1fr 1fr", gridGap: "20px" }}>
                            <div >
                              <Skeleton height={80} mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton height={8} mt={6} width="70%" radius="xl" style={{ marginBottom: "10px" }} />
                            </div>
                            <div >
                              <Skeleton height={50} circle mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton height={8} mt={6} width="100%" radius="xl" style={{ marginBottom: "10px" }} />
                            </div>
                            <div >
                              <Skeleton height={50} circle mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton height={8} mt={6} width="70%" radius="xl" style={{ marginBottom: "10px" }} />
                            </div>
                            <div >
                              <Skeleton height={50} circle mb="xl" />
                              <Skeleton height={8} radius="xl" />
                              <Skeleton height={8} mt={6} radius="xl" />
                              <Skeleton height={8} mt={6} width="100%" radius="xl" style={{ marginBottom: "10px" }} />
                            </div>
                          </div>
                        ) : user ? (
                          <Home />
                        ) : (
                          <Login beforeInstallPrompt={beforeInstallPrompt} />
                        )
                      }
                    />


                    <Route path="jobdescription" element={<JobDescription />} />
                    <Route path='coursedetail' element={<Coursedescription />} />
                    <Route
                      path="verification"
                      element={<VerifyEmailComponent />}
                    />
                    <Route
                      path="landing-greater-noida"
                      element={<Landinggreaternoida />}
                    />
                    <Route
                      path="notification"
                      element={<PublicJobSearchResult />}
                    />
                    <Route path="policy" element={<Policy />} />
                    <Route
                      path="*"
                      element={<Navigate to="/" replace={true} />}
                    />
                    <Route element={<ProtectedRoute />}>
                      <Route path="jobs" element={<Jobs />} />
                      <Route path="jobcategory" element={<JobCategory />} />
                      <Route path="joblocation" element={<JobLocation />} />
                      <Route
                        path="onboardingbasicinformation"
                        element={<OnboardingQuestions />}
                      />
                      <Route
                        path="createprofileonboarding"
                        element={<CreateProfileOnboarding />}
                      />
                      <Route
                        path="createprofilesingleonboarding"
                        element={<CreateProfileSingleOnboarding />}
                      />
                      <Route path="whitecollar" element={<WhiteCollar />} />
                      <Route
                        path="hometrandingmidbannerdescription"
                        element={<HomeTrandingMidBannerDescription />}
                      />
                      <Route
                        path="stateJobSection"
                        element={<StateJobSection />}
                      />
                      <Route
                        path="salaryJobSection"
                        element={<SalaryJobSection />}
                      />
                      <Route
                        path="experienceJobSection"
                        element={<ExperienceJobSection />}
                      />
                      <Route path="editprofile" element={<EditProfile />} />
                      <Route path="myprofile" element={<MyProfile />} />
                      <Route path="salarymeter" element={<SalaryPredictor />} />
                      <Route path="predict" element={<Predictedsalary />} />
                      <Route
                        path="chatbotsinglequestionanswer"
                        element={<ChatbotSingleQuestionAnswer />}
                      />
                      <Route path="chatbot" element={<Chatbot />} />
                      <Route path="education" element={<Eductaion />} />
                      <Route path="editeducation" element={<EditEducation />} />
                      <Route path="otherdetails" element={<OtherDetails />} />
                      <Route path="experience" element={<Experience />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="notificationDetail" element={<Notification />} />
                      <Route path="tutoronboard" element={<TutorOnboard />} />
                      <Route path="comingsoon" element={<ComingSoon />} />
                      <Route path="aitutor" element={<AITutor />} />
                      <Route path="aisport" element={<SportAI />} />
                      <Route path="aiinterview" element={<InterviewAI />} />
                      <Route path="aishopping" element={<ShoppingAI />} />
                      <Route path="learnenglish" element={<LeanEnglish />} />
                      <Route path="tutorlearnai" element={<TutorWheel />} />
                      <Route path="appliedjob" element={<AppliedJob />} />
                      <Route path="editexperience" element={<EditExperience />} />
                      <Route path="courses" element={<Course />} />
                      <Route path="play" element={<Play />} />
                      <Route path="shraminshala" element={<Shraminshala />} />
                      {/* <Route
                      path="shraminshala"
                      element={
                        user ? <Shraminshala /> : <Navigate to="/login" />
                      }
                    /> */}
                      <Route
                        path="coursedetail"
                        element={<Coursedescription />}
                      />
                      <Route
                        path="/jobsearchresult"
                        element={<JobSearchResult />}
                      />
                      {/* <Route 
                    path="/verification"
                    element={<VerifyEmailComponent/>}
                    /> */}
                    </Route>
                  </Routes>
                </main>
                {user &&
                  pathname !== "/joblocation" &&
                  pathname !== "/jobLocation" &&
                  pathname !== "/onboardingbasicinformation" &&
                  pathname !== "/whitecollar" &&
                  pathname !== "/createprofileonboarding" &&
                  pathname !== "/createprofilesingleonboarding" &&
                  pathname !== "/landing-greater-noida" && (
                    <footer className="app-footer">
                      <BottomNavigation />
                    </footer>
                  )}
              </div>
            </div>
          </div>
        )}
      </QueryClientProvider>
    </I18nProvider>
  );
}

export default App;
