import React from 'react'
import { TextInput, Checkbox, Button, Group, Text, Container, Flex, Center, Box, Loader, Radio, Select, LoadingOverlay, NumberInput, Modal } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FaPhoneAlt } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import { MdEmail } from "react-icons/md";
import { RiGraduationCapFill } from "react-icons/ri";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import { MdWork } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { useGetTradeDetailsQuery, useGetProfileDetailsQuery, useGetLocationQuery } from '../redux/api/apiSlice';
import { useGetEducationDetailsQuery } from '../redux/api/apiSlice';
import { useGetExperienceDetailsQuery } from '../redux/api/apiSlice';
import { useEditProfileDetailsMutation } from '../redux/api/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import store from "../redux/store"
import { useDisclosure } from '@mantine/hooks';
import { Trans, t } from '@lingui/macro';
import { useLingui } from "@lingui/react";
import { candidateData } from '../redux/userSlice';
import { IoArrowBack } from "react-icons/io5";

import OtpInput from 'react-otp-input';
import '@mantine/notifications/styles.css';
import { notifications } from '@mantine/notifications';
import { useNavigation } from '../context/NavigationContext';



const CreateProfileSingleOnboarding = () => {
    const { i18n } = useLingui();
    const [otp, setOtp] = useState('');
    const token = useSelector((state) => state?.user?.token)
    const dispatch = useDispatch();
    // const userDetails = store.getState().user.candidateData
    const userDetails = useSelector((state) => state?.user.candidateData)

    // console.log(userDetails, "userDetailsvvvvv");
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedValue2, setSelectedValue2] = useState(null);
    const [selectedValue3, setSelectedValue3] = useState(null);
    const [selectedValue4, setSelectedValue4] = useState(null);
    const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);
    const [reqID, setReqID] = useState('')
    const [userDeatailId, setUserDeatailId] = useState(null);

    let mobileNofilled = userDetails?.mobileNo

    let emailfilled = userDetails?.email

    //console.log("mobileNofilledaaa", userDetails)
    
    var userId = localStorage.getItem('userId');
    const navigate = useNavigate()
    const { prevUrl } = useNavigation() || { prevUrl: '/' };

    let baseUrl = '';
    let relativeUrl = '/';

    if (prevUrl && prevUrl.startsWith('http')) {
        baseUrl = new URL(prevUrl).origin;
        relativeUrl = prevUrl.replace(baseUrl, "");
    } else {
        console.warn("Invalid or missing prevUrl:", prevUrl);
    }

    console.log("previous url ---" + relativeUrl)
    const arrowBack = () => {
        navigate('/joblocation');
    }



    const handleSelectChange = (event) => {
        const filteredData = tradeData.filter(item => item.masterName === event);
        setSelectedValue(event)

    };
    const handleSelectChange2 = (event) => {
        const filteredData = educationData.filter(item => item.masterName === event);
        setSelectedValue2(event)

    };
    const handleSelectChange3 = (event) => {
        const filteredData = experienceData.filter(item => item.masterName === event);
        setSelectedValue3(event)

    };

    const handleSelectChange4 = (event) => {
        const filteredData = locationData.filter(item => item.masterName === event);
        setSelectedValue4(event)

    };

    const [loading, { open: openLoading, close: closeLoading }] = useDisclosure(false);

    const { data: tradeData, isLoading, isError, isFetching, refetch } = useGetTradeDetailsQuery({
        skip: !token
    })

    const { data: candidateData1, isLoadingProfile, isErrorProfile, isFetchingProfile, refetch: refetchProfile } = useGetProfileDetailsQuery(userId, {
        skip: !token
    })


    const { data: educationData, isLoading: eduLoading, isError: eduError, isFetching: eduFetching, refetch: eduRefetch } = useGetEducationDetailsQuery({
        skip: !token
    })

    const { data: experienceData, isLoading: expLoading, isError: expError, isFetching: expFetching, refetch: expRefetch } = useGetExperienceDetailsQuery({
        skip: !token
    })

    const { data: locationData, isLoading: locationLoading, isError: locationError, isFetching: locationFetching, refetch: locationRefetch } = useGetLocationQuery({
        skip: !token
    })


    // const [editProfile] = useEditProfileDetailsMutation()


    //const [editProfile, { data: resultSuccess, error: error1 }] = useEditProfileDetailsMutation();
    //console.log("result1inCreateoooooooo2222", resultSuccess, error1)

    // useEffect(() => {
    //     if (resultSuccess) {
    //         console.log("result1inCreateoooooooo", resultSuccess, error1)
    //             if(resultSuccess?.message === 'successfully updated'){
    //                 navigate('/');
    //                 closeLoading()
    //             } 
    //     }
    // }, [resultSuccess]);


    // dispatch(candidateData(candidateDetails));

    useEffect(() => {
        if (token) {
            refetch();
            eduRefetch()
            expRefetch()
            locationRefetch()
            refetchProfile()
        }
    }, [token, refetch, eduRefetch, locationRefetch, expRefetch]);




    useEffect(() => {
        if (candidateData1) {
            dispatch(candidateData(candidateData1));

        }

        setTimeout(() => {
            // console.log('qqqqqqnnnnnnnnn')
            if(userDetails){
                setUserDeatailId(userDetails?.id)
            }
          }, 4000);

        

        localStorage.setItem('relativeUrl', relativeUrl)

        if (userDetails?.name !== null && userDetails?.name !== undefined && userDetails?.trade?.id !== null && userDetails?.trade?.id !== undefined) {
            navigate(relativeUrl ? relativeUrl : "/");
        }

        //console.log(userDetails?.id, relativeUrl, "ppppppppppppp")

    }, [candidateData1]);


  


    const form = useForm({
           initialValues: {
               name: userDetails?.name || '',
               age: userDetails?.age || '',
               gender: userDetails?.gender || '',
               mobile: userDetails?.mobileNo || '',
               email: userDetails?.email || '',
               trade: userDetails?.trade?.id?.toString() || '',
               education: userDetails?.education?.id?.toString() || '',
               experience: userDetails?.experience?.id?.toString() || '',
               location: userDetails?.location?.id?.toString() || '',
               bike: userDetails?.bikeAndDl?.toLowerCase() || '',
            // passport: userDetails?.passport == null ? "" : userDetails?.passport == true ? "true" : "false",
           },
   
           validate: {

            // email: (value) => mobileNofilled 
            // ? (/^\S+@\S+$/.test(value) ? null : <Trans>Invalid email</Trans>) 
            // : null,
    
            // mobile: (value) => !mobileNofilled 
            // ? (value?.length < 10 || value?.length > 13 
            //     ? <Trans>Please enter a valid mobile number 🤥</Trans> 
            //     : null) 
            // : null,

            email: (value) => {
                if (mobileNofilled) {
                    if (!/^\S+@\S+$/.test(value)) {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        return <Trans>Invalid email</Trans>;
                    }
                }
                return null;
            },
    
            mobile: (value) => {
                if (!mobileNofilled) {
                    if (value?.length < 10 || value?.length > 13) {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        return <Trans>Please enter a valid mobile number 🤥</Trans>;
                    }
                }
                return null;
            },
              
              
               name: (value) => {
                if (!value || value.trim().length < 2) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    return <Trans>Name must have at least 2 letters</Trans>;
                }
                if (/^\d+$/.test(value)) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    return <Trans>Enter Your Name</Trans>;
                }
                return null;
            },

            age: (value) => {
                if (value?.length == 0) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    return <Trans>Age is Required</Trans>;
                }
                if (value < 16) {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    return <Trans>Age must be 16 years or above</Trans>;
                }
                return null;
            },

            gender: (value) => {
                if (value == "") {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    return <Trans>Gender is Required</Trans>;
                }
                return null;
            },

            trade: (value) => {
                if (value == '') {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    return <Trans>Trade is Required</Trans>;
                }
                return null;
            },
    
            //    mobile: (value) => (value?.length < 10 ? <Trans>Please enter a 10 digits mobile number 🤥</Trans> : value?.length > 13 ? <Trans>Please enter a 10 digits mobile number 🤥</Trans> : null),
            //    trade: (value) => (value == '' ? <Trans>Trade is Required</Trans> : null),
               education: (value) => (value == '' ? <Trans>Education is Required</Trans> : null),
               experience: (value) => (value == '' ? <Trans>Experience is Required</Trans> : null),
               location: (value) => (value == '' ? <Trans>Location is Required</Trans> : null),
            //    gender: (value) => (value == "" ? <Trans>Gender is Required</Trans> : null),
               bike: (value) => (value == '' ? <Trans>Driving License is required</Trans> : null),
            //    age: (value) => (value?.length == 0 ? <Trans>Age is Required</Trans> : value < 16 ? <Trans>Age must be 16 years or above</Trans> : null),
            // passport: (value) => (value == '' ? <Trans>Passport is required</Trans> : null)
           },
       });


    
    const handleSubmit = async (values) => {

       // console.log(values,"valuesjjjjjjjj")

        openLoading()

        //////////////

        const url = import.meta.env.VITE_BASE_URL + '/candidate';

        const obj = {};
               obj.name = values.name,
               obj.tradeId = parseInt(values.trade, 10),
               obj.educationId = parseInt(values.education, 10),
               obj.experienceId = parseInt(values.experience, 10),
               obj.locationId = parseInt(values.location, 10),
            obj.age = parseInt(values.age, 10),
            obj.gender = values.gender,
            obj.mobileNo = parseInt(values.mobile, 10),
            obj.email = values.email ? values.email : emailfilled,
            obj.bikeAndDl = values.bike
            // obj.passport = values.passport


        try {
            const response = await fetch(`${url}`, {

                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(obj)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok in UpdateProfileData');
            }

            const updateprofileResult = await response.json();
            //console.log("profileResultdata11",updateprofileResult)
            refetchProfile()

            if (updateprofileResult) {
                console.log("result1inCreateoooooooo", updateprofileResult)
                if (updateprofileResult?.message === 'successfully updated' || updateprofileResult?.message === 'Resume Is Already Present!!!!!') {
                    navigate(relativeUrl ? relativeUrl : "/");
                    closeLoading()
                }
            }


        } catch (error) {
            closeLoading()
            console.error('Error fetching update profile data:', error);
        }

    }





    return (
        <>

        {

        }

          {userDeatailId ? (
           <div>

            {/* <Container fluid bg="white" p="lg" style={{ borderBottom: "0.5px solid #D2D2D2" }}>
                <Group align="center">
                    
                    <span style={{ fontWeight: "bold", flex: "1", textAlign: "center" }}><Trans>Basic Information</Trans></span>

                </Group>
            </Container> */}

            <Box pos="relative">
                <LoadingOverlay visible={loading} zIndex={"1"} overlayProps={{ radius: "sm", }} />
                <Box style={{ marginTop: "0px" }}>
                    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}
                        style={{ display: "flex", flexDirection: "column", gap: ".5rem", height: "auto", padding: "10px" }}>
                        <TextInput style={{ marginTop: "5px" }}
                            withAsterisk
                            label={<Trans>What is Your Name ?</Trans>}
                            // leftSection={<IoPerson />}
                            placeholder={t`Enter Your Name`}
                            {...form.getInputProps('name')}
                        />

                         <NumberInput
                                                    withAsterisk
                                                    label={<Trans>Age</Trans>}
                                                    placeholder={t`Enter Your Age`}
                                                    // leftSection={<SlCalender />}
                                                    {...form.getInputProps('age')}
                        
                                                    max={99}
                                                    maxLength={2}
                                                />
                        
                                                {/* <div>
                                                    <label style={{ fontSize: "14px" }}><Trans>What is Your Gender?</Trans></label>
                                                    <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                                                        <Radio
                                                            label={<Trans>Male</Trans>}
                                                            value="male"
                                                            checked={form.values.gender === 'male' ? true : false}
                                                            onChange={(event) => form.setFieldValue('gender', event.currentTarget.value)}
                                                        />
                                                        <Radio
                                                            label={<Trans>Female</Trans>}
                                                            value="female"
                                                            checked={form.values.gender === 'female' ? true : false}
                                                            onChange={(event) => form.setFieldValue('gender', event.currentTarget.value)}
                                                        />
                                                    </div>
                                                    {form?.errors?.gender &&
                                                        <p style={{ display: "block", fontSize: "12px", color: "#FF5252", fontWeight: "500" }}>{<Trans>Gender is Required</Trans>}</p>
                                                    }
                                                </div> */}

<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
    {/* <label style={{ fontSize: "14px", whiteSpace: "nowrap", fontWeight:"500" }}>
        <Trans>What is Your Gender?</Trans>
    </label> */}
    <label style={{ fontSize: "14px", whiteSpace: "nowrap", fontWeight: "500" }}>
        <Trans>What is Your Gender?</Trans> <span style={{ color: "red" }}>*</span>
    </label>

    <div style={{ display: "flex", gap: "1.5rem" }}>
        <Radio
            label={<Trans>Male</Trans>}
            value="male"
            checked={form.values.gender === 'male'}
            onChange={(event) => form.setFieldValue('gender', event.currentTarget.value)}
        />
        <Radio
            label={<Trans>Female</Trans>}
            value="female"
            checked={form.values.gender === 'female'}
            onChange={(event) => form.setFieldValue('gender', event.currentTarget.value)}
        />
    </div>
</div>

{form?.errors?.gender && (
    <p style={{ fontSize: "12px", color: "#FF5252", fontWeight: "500", marginTop:"-5px", }}>
        <Trans>Gender is Required</Trans>
    </p>
)}



                        

                     {userDetails?.mobileNo !== null ? (

                            <div style={{ position: "relative" }}>
                                                       <TextInput
                                                           withAsterisk
                                                           label={<Trans>Email</Trans>}
                                                           leftSection={<MdEmail />}
                                                           placeholder={t`Enter Your Email`}
                                                           {...form.getInputProps('email')}
                                                       />
                                                   </div>
                           

                     

                         ) : (
                            <div style={{ position: "relative" }}>
                            <TextInput
                                key={i18n.locale}
                                withAsterisk
                                label={<Trans>Your Mobile Number</Trans>}
                                leftSection={<FaPhoneAlt />}
                                placeholder={t`Enter Your Number`}
                                {...form.getInputProps('mobile')}
                                styles={{
                                    input: { height: "45px" }
                                }}
                            />
                           

                        </div>
                         )}


                        <Select
                            label={<Trans>Trade/Job Category</Trans>}
                            withAsterisk
                            placeholder={t`Select Trade`}
                            data={tradeData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange}
                            value={selectedValue}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('trade',)}
                        />


                        <Select
                            label={<Trans>Your Education Qualification</Trans>}
                            withAsterisk
                            placeholder={t`Select Qualification`}
                            data={educationData?.map((item) => ({
                                value: item?.id.toString(),
                                label: item?.masterName
                            }))}
                            searchable
                            onChange={handleSelectChange2}
                            value={selectedValue2}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('education',)}
                        />

                        <Select
                            label={<Trans>Your Work Experience</Trans>}
                            withAsterisk
                            placeholder={t`Select Work Experience`}
                            data={experienceData?.map((item) => ({
                                value: item?.id.toString(),   // Use the id as the value
                                label: item?.masterName // Display the masterName
                            }))}
                            searchable
                            onChange={handleSelectChange3}
                            value={selectedValue3}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('experience',)}
                        />

                          <Select
                            label={<Trans>Your Work Location</Trans>}
                            withAsterisk
                            placeholder={t`Select Work Location`}
                            data={locationData?.map((item) => ({
                                value: item?.id.toString(),   // Use the id as the value
                                label: item?.masterName // Display the masterName
                            }))}
                            searchable
                            onChange={handleSelectChange4}
                            value={selectedValue4}
                            nothingFoundMessage={t`Nothing found...`}
                            {...form.getInputProps('location',)}
                        />



                        <div>
                            <label style={{ fontSize: "14px", fontWeight:"500" }}><Trans>Do you have a bike or Driving License?</Trans> <span style={{ color: "red" }}>*</span></label>
                            <div style={{ display: "flex", gap: "2rem", marginTop: "7px" }}>
                                <Radio
                                    label={<Trans>Yes</Trans>}
                                    value="yes"
                                    checked={form.values.bike === 'yes'}
                                    onChange={(event) => form.setFieldValue('bike', event.currentTarget.value)}
                                />
                                <Radio
                                    label={<Trans>No</Trans>}
                                    value="no"
                                    checked={form.values.bike === 'no'}
                                    onChange={(event) => form.setFieldValue('bike', event.currentTarget.value)}
                                />
                            </div>
                            {form?.errors?.bike &&
                                <p style={{ display: "block", fontSize: "12px", color: "#FF5252", fontWeight: "500" }}>{<Trans>Driving License is required</Trans>}</p>
                            }
                        </div>




                        <Group justify="center" mt="md" >
                            <Button

                                type="submit" style={{
                                    width: '100%',
                                    marginBottom: '5rem',
                                }}><Trans>Save</Trans></Button>
                        </Group>
                    </form>
                </Box >
            </Box>

            </div>

            ) : (
              <div>
              <Center>
                        <Loader style={{ marginTop: "220px" }} color="#034078" size={30} />
                      </Center>
              </div>
                      
                      )}



        </>
    )
}

export default CreateProfileSingleOnboarding
