import React from 'react'
import { useSearchResultsQuery } from '../redux/api/apiSlice'
import { useSelector } from 'react-redux'
import styles from '../styles/Jobsearchresult.module.css'
import { Loader, Text } from '@mantine/core';
import { ScrollArea, Box } from '@mantine/core';
import AutocompleteLoading from './AutoCompleteSearch';
import { Trans } from '@lingui/macro';
import GovtJobSearchCard from './GovtJobSearchCard';
import JobSearchCard from './JobSearchCard';
import WalkinJobSearchCard from './WalkinJobSearchCard';
import { useNavigation } from '../context/NavigationContext';
import { useEffect } from 'react';
import OverseasJobSearchCard from './OverseasJobSearchCard';

const JobSearchResult = () => {
    const token = useSelector((state) => state?.user?.token)
    const searchParams = new URLSearchParams(window.location.search);
    const searchParamsData = encodeURIComponent(searchParams.get('searchtext'));
    const { prevUrl, resetPrevUrl } = useNavigation() || { prevUrl: '/' };


    const candidateId = localStorage.getItem('candidateId')

    const { data, refetch, isFetching, isLoading } = useSearchResultsQuery({ candidateId, searchParamsData }, {
        skip: !token
    })



    const walkInJobs = data?.WalkInJobs
    const GovermentJobs = data?.GovernmentJobs
    const Jobs = data?.Jobs
    const Overseas = data?.OverseasJobs

    useEffect(() => {
        resetPrevUrl()
    }, [])

    return (
        <>
            <AutocompleteLoading />
            <div className={styles.container}>
                {
                    isLoading ? <Loader color="blue" style={{ marginLeft: "45%", marginTop: "50%" }} /> : <div style={{ minHeight: "60rem", marginBottom: "3.5rem" }}>

                        {/* Jobs */}
                        <div>
                            <span className={styles.card_container_heading}><Trans>Jobs</Trans></span>


                            {Jobs?.length > 0 ? <div className={styles.card_container}>
                            {[...Jobs]
                            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                            .map((item) => (
                                    <JobSearchCard
                                        key={item?.id}
                                        id={item?.id}
                                        title={item?.jobTitle}
                                        company={item?.companyName}
                                        trade={item?.trade?.masterName}
                                        location={item?.location?.masterName}
                                        state={item?.location?.parentModel?.masterName}
                                        experience={item?.experience?.masterName}
                                        salary={item?.salary?.masterName}
                                        itemTypeId={item?.itemPostingTypeId}
                                        logo={item?.logoVirtualPath ? item?.logoVirtualPath : item?.employer?.logoVirtualPath}
                                    />
                                ))}
                            </div>: <div style={{ height : "90px",  display : "flex", alignItems : "center", justifyContent: "center"}}>
                                <span style={{fontSize :"18px"}}>
                                    No Jobs Found!
                                </span>
                                </div>}

                        </div>


                        {/* Govt job */}
                        <div>
                            <span className={styles.card_container_heading}><Trans>Govt. Jobs</Trans></span>


                            {GovermentJobs?.length > 0 ? <div className={styles.card_container}>
                            {[...GovermentJobs]
                            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                ?.map((item) => (
                                    <GovtJobSearchCard
                                        id={item?.id}
                                        title={item?.jobTitle}
                                        company={item?.companyName}
                                        trade={item?.trade?.masterName}
                                        location={item?.location?.masterName}
                                        state={item?.location?.parentModel?.masterName}
                                        experience={item?.experience?.masterName}
                                        salary={item?.salary?.masterName}
                                        logo={item?.logoVirtualPath ? item?.logoVirtualPath : item?.employer?.logoVirtualPath}

                                    />
                                ))}
                            </div>: <div style={{ height : "90px",  display : "flex", alignItems : "center", justifyContent: "center"}}>
                                <span style={{fontSize :"18px"}}>
                                    No Jobs Found!
                                </span>
                                </div>}

                        </div>

                        {/* Walk-In-Jobs */}
                        <div>
                            <span className={styles.card_container_heading}><Trans>Walk-In Jobs</Trans></span>

                            {walkInJobs?.length > 0 ? <div className={styles.card_container}>
                            {[...walkInJobs]
                            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                            ?.map((item) => (
                                    <WalkinJobSearchCard
                                        id={item?.id}
                                        title={item?.jobTitle}
                                        company={item?.companyName}
                                        trade={item?.trade?.masterName}
                                        location={item?.location?.masterName}
                                        state={item?.location?.parentModel?.masterName}
                                        experience={item?.experience?.masterName}
                                        salary={item?.salary?.masterName}
                                        logo={item?.logoVirtualPath}
                                    />
                                ))}
                            </div> : <div style={{ height : "90px",  display : "flex", alignItems : "center", justifyContent: "center"}}>
                                <span style={{fontSize :"18px"}}>
                                    No Jobs Found!
                                </span>
                                </div>}

                        </div>

                        {/* Overseas */}

                        <div>
                            <span className={styles.card_container_heading}><Trans>Overseas Jobs</Trans></span>

                            {
                                Overseas?.length > 0? <div className={styles.card_container}>
                                {[...Overseas]
                            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                            ?.map((item) => (
                                    <OverseasJobSearchCard
                                        id={item?.id}
                                        title={item?.jobTitle}
                                        company={item?.companyName}
                                        trade={item?.trade?.masterName}
                                        location={item?.location?.masterName}
                                        state={item?.location?.parentModel?.masterName}
                                        experience={item?.experience?.masterName}
                                        salary={item?.salary?.masterName}
                                        logo={item?.logoVirtualPath}
                                    />
                                ))}
                            </div> : <div style={{ height : "90px",  display : "flex", alignItems : "center", justifyContent: "center"}}>
                                <span style={{fontSize :"18px"}}>
                                    No Jobs Found!
                                </span>
                                </div>}

                        </div>

                    </div>
                }
            </div>
        </>
    )
}

export default JobSearchResult